import $ from 'cash-dom';

export const sectionNavComponent = async () => {
  const $menu = $('#menu');
  const $menuCheck = $('#menu-check');

  if ($menu.length === 0 || $menuCheck.length === 0) {
    return;
  }

  const headerHeight = 152;
  const originalMarginTopClass = 'mt-12';

  const hasOriginalMarginTop = $menu.hasClass(originalMarginTopClass);

  const $placeholder = $('<div></div>');
  $placeholder.hide();

  $menu.after($placeholder);

  const menuHeight = $menu.outerHeight(true);

  const observerCallback = (entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        $menu.addClass('fixed-menu');
        if (hasOriginalMarginTop) {
          $menu.removeClass(originalMarginTopClass);
        }
        $placeholder.show();
        $placeholder.height(menuHeight);
      } else {
        $menu.removeClass('fixed-menu');
        if (hasOriginalMarginTop) {
          $menu.addClass(originalMarginTopClass);
        }
        $placeholder.hide();
      }
    });
  };

  const offset = hasOriginalMarginTop ? 48 : 0;

  const observer = new IntersectionObserver(observerCallback, {
    root: null,
    rootMargin: `-${headerHeight - offset}px 0px 0px 0px`,
    threshold: 0,
  });

  observer.observe($menuCheck.get(0));
};

export const sectionNavActiveComponent = async () => {
  const $navItems = $('.section-nav-item');
  const sections = [];

  if ($navItems.length === 0) {
    return;
  }

  $navItems.each(function() {
    const $navItem = $(this);
    const href = $navItem.attr('href');
    if (href && href.startsWith('#')) {
      const sectionId = href.substring(1);
      const $section = $(`#${sectionId}`);
      if ($section.length) {
        sections.push({
          navItem: $navItem,
          section: $section,
        });
      }
    }
  });

  const observerOptions = {
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: 0.5,
  };

  const observerCallback = (entries) => {
    entries.forEach(entry => {
      console.log(entry.target, entry.isIntersecting);
      const $section = $(entry.target);
      const isIntersecting = entry.isIntersecting && entry.intersectionRatio > 0;
      const navIndex = sections.findIndex(item => item.section.get(0) === entry.target);
      const navItemData = sections[navIndex];

      if (navIndex > -1) {
        if (isIntersecting) {
          sections.forEach(item => {
            item.navItem.removeClass('text-red border-b border-red').addClass('text-gray-dark');
          });
          navItemData.navItem.addClass('text-red border-b border-red').removeClass('text-gray-dark');
        }
      }
    });
  };

  const observer = new IntersectionObserver(observerCallback, observerOptions);

  sections.forEach(item => {
    observer.observe(item.section.get(0));
  });

  $navItems.on('click', function(event) {
    const $navItem = $(this);
    const href = $navItem.attr('href');
    console.log(href);
    if (href && href.startsWith('#')) {
      const sectionId = href.substring(1);
      const $section = $(`#${sectionId}`);

      if ($section.length) {
        $('html, body').animate({
          scrollTop: $section.offset().top - 152,
        }, 500);

        sections.forEach(item => {
          item.navItem.removeClass('text-red border-b border-red').addClass('text-gray-dark');
        });
        $navItem.addClass('text-red border-b border-red').removeClass('text-gray-dark');
      }
    }
  });
};
