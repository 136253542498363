import $ from 'cash-dom';
import anime from "animejs";

export const examplesComponent = async () => {
  const scroll = $('#examples-desktop-scroll').get(0);
  const $back = $('#examples-desktop-back');
  const $forward = $('#examples-desktop-forward');

  if (!scroll)
    return;

  const count = parseInt(scroll.getAttribute('data-count'));
  let activeIndex = 0;

  const update = () => {
    if (activeIndex > 0)
      $back
        .removeClass('bg-transparent border-gray text-gray hover:border-gray-dark hover:text-gray-dark')
        .addClass('bg-white border-red text-red hover:border-red-dark hover:text-red-dark');
    else
      $back
        .removeClass('bg-white border-red text-red hover:border-red-dark hover:text-red-dark')
        .addClass('bg-transparent border-gray text-gray hover:border-gray-dark hover:text-gray-dark');
    if (activeIndex < count - 1)
      $forward
        .removeClass('bg-transparent border-gray text-gray hover:border-gray-dark hover:text-gray-dark')
        .addClass('bg-white border-red text-red hover:border-red-dark hover:text-red-dark');
    else
      $forward
        .removeClass('bg-white border-red text-red hover:border-red-dark hover:text-red-dark')
        .addClass('bg-transparent border-gray text-gray hover:border-gray-dark hover:text-gray-dark');

    anime({
      targets: scroll,
      left: -activeIndex * scroll.offsetWidth,
      duration: 250,
      easing: 'easeOutQuad',
    });
  };

  $back.on('click', () => {
    if (activeIndex > 0) {
      --activeIndex;
      update();
    }
  });
  $forward.on('click', () => {
    if (activeIndex < count - 1) {
      ++activeIndex;
      update();
    }
  });
};
